/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* ng-select */
@import "~@ng-select/ng-select/themes/material.theme.css";

/* Swiper */
@import '~swiper/swiper.min.css';
@import '~@ionic/angular/css/ionic-swiper';

.hidden {
    visibility: hidden;
    width: 0 !important;
    height: 0 !important;
}

.hiddenBlock {
    visibility: hidden;
    width: 0 !important;
    height: 0 !important;
    display: block;
}

.displayNone {
    display: none;
}

.centerContent {
    text-align: center;
}

.alignRightContent {
    text-align: right;
}

.floatRight {
    float: right;
}

.underline {
    text-decoration: underline;
}

.disclaimerText {
    font-size: 0.8em;
    opacity: 0.7;
}

.noWrap {
    white-space: nowrap;
}

.textWrap {
    white-space: normal !important;
}

.bold {
    font-weight: bold;
}

.lineFeedBreak {
    white-space: pre;
}

.ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.ellipsisForce {
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
}

.marginTop {
    margin-top: 1em;
}

.marginBottom {
    margin-bottom: 1em;
}

.marginLeft {
    margin-left: 1em;
}

.danger {
    color: var(--color-danger);
}

.hoverHighlight {
    &:hover {
        color: var(--ion-color-primary);

        ion-text, td, span, fa-icon {
            color: var(--ion-color-primary);
        }
    }
}

.actionButton {
    min-width: initial !important;
    margin-right: 0.5em !important;
}

.actionButtons {
    button {
        min-width: initial !important;
    }

    button, base-process-button {
        margin-right: 0.25em;
        margin-top: 1px;
        margin-bottom: 1px;
    }

    button:only-child, base-process-button:only-child {
        margin-right: 0;
    }
}

.mat-button, .mat-flat-button, .mat-stroked-button {
    fa-icon + label {
        margin-left: 0.5em;
    }
}

.linkLabel {
    font-size: 0.8em;
    color: var(--ion-color-primary);
    cursor: pointer;
    user-select: none;

    &:hover {
        text-decoration: underline;
    }
}

.mat-form-field-suffix .mat-icon-button {
    transform: translateY(-4px);
}

.mat-stroked-button:not(.mat-button-disabled) {
    border-color: initial;
}

.mat-form-field {
    width: 100%;
}

.mat-checkbox {
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0.75em;
}

.groupedInputs {
    mat-form-field {
        width: calc(50% - 0.5em);

        &:first-child {
            margin-right: 1em;
        }
    }
}

.mat-tooltip {
    font-size: 0.9rem;
}

.multilineTooltip {
    white-space: pre-line;
}

.fadeOutAfterShown {
    animation: fadeOutAfterShown 0.5s 1;
    animation-fill-mode: forwards;
    animation-delay: 5s;
}

.contentBoxInfoText {
    margin-top: 2em;
    color: var(--ion-color-medium);
    font-size: 0.9em;
}

.rotateOnHover:hover {
    transform: rotateZ(90deg);
    transition: transform 0.1s ease-in-out;
}

@keyframes fadeOutAfterShown {
    from {
        opacity: 1.0;
    }
    to {
        opacity: 0.0;
    }
}

.bumpAfterShown {
    animation: bumpAfterShown 0.3s ease-in-out;
    animation-fill-mode: forwards;
}

@keyframes bumpAfterShown {
    80% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

.focusMode {
    ion-content {
        --padding-top: 0;
    }
}

.pageTitle {
    color: var(--app-color-document-title);
    font-weight: bold;
}

h3.pageTitle {
    margin-top: 0;
}

.contentBox {
    --background: var(--app-color-content-background);
    background: var(--app-color-content-background);
    border-radius: 0.5em;
    margin-bottom: 1em;
}

.contentPadding {
    padding: 8px;
}

#background-content, ion-content {
    --background: var(--ion-background-color);
}

ion-modal ion-content {
    --background: transparent;
}

fa-icon {
    pointer-events: none;
}

ion-list, ion-item, ion-accordion-group, ion-accordion, ion-label, ion-item-divider {
    --background: transparent;
    background: transparent;
}

ion-item-divider {
    border-bottom: none;
    margin-bottom: 0;
}

ion-button {
    text-transform: none;
}

ion-popover {
    --width: auto;
    --max-width: 100%;
}

base-page-content-container {
    width: 100%;
    height: 100%;
}

.header-md::after {

}

.clickable {
    cursor: pointer !important;
}

.scrollX {
    overflow-x: auto;
}

.scrollY {
    overflow-y: auto;
}

.modalDialog {
    --border-radius: 1em;

    &.fullscreen {
        --min-width: 100%;
        --min-height: 100%;
        --border-radius: 0;
    }

    &.large {
        --max-width: 1000px;
        --max-height: 900px;
        --width: 100%;
        --height: 100%;
    }

    &.small {
        --max-width: 600px;
        --max-height: 400px;
        --width: 100%;
        --height: 100%;
    }

    &.tiny {
        --max-width: 400px;
        --max-height: 300px;
        --width: 100%;
        --height: 100%;
    }
}

ion-modal.fullscreen + ion-modal:not(.fullscreen), ion-modal.large + ion-modal:not(.large) {
    --backdrop-opacity: 0.32 !important;
}

.modalHeader {
    ion-toolbar {
        --background: var(--app-color-modal-background);
        --border-width: 0;
        --color: var(--app-color-modal-title);
        text-align: left;
    }
}

.modalContent {
    --background: var(--app-color-modal-background);

    ion-list {
        --background: var(--app-color-modal-background);
        background: var(--app-color-modal-background);
    }
}

.modalFooter {
    ion-toolbar {
        --background: var(--app-color-modal-background);
    }
}

.coreDataGroup {
    background: var(--app-color-content-background);
    border-radius: 0.5em;
    padding: 1em;
    margin-bottom: 1em;

    ion-list-header {
        font-size: 1.1rem;
    }
}

@media screen and (max-width: 767px) {
    .modalDialog {
        --min-width: 100%;
        --min-height: 100%;
        --border-radius: 0;
    }
}

@media screen and (max-width: 1100px) {
    .modalDialog.large {
        --min-width: 100%;
        --min-height: 100%;
        --border-radius: 0;
    }
}

ion-item.readonly {
    pointer-events: none;

    ion-select {
    }

    .select-icon {
        display: none !important;
    }
}

.cameraContent {
    width: 100%;
    height: 100%;
}

mat-form-field {
    &.readonly {
        .mat-form-field-underline {
            display: none !important;
        }
    }

    &.mat-form-field-appearance-fill {
        .mat-form-field-flex {
            border-top-left-radius: 0.5em;
            border-top-right-radius: 0.5em;
        }
    }
}

app-input-dialog {
    .mat-form-field-flex {
        padding-top: 0 !important;
    }
}

.PinturaShapeStyleLabel {
    text-transform: initial !important;
}

.angularMaterialNoSpacing {
    .mat-form-field-wrapper {
        padding: 0 !important;
        margin: 0 !important;
    }
}

.alignTop {
    .mat-list-item-content {
        align-items: flex-start !important;
    }
}
