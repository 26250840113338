// http://mcg.mbitson.com/#!?primary=%234d4aca&accent=%233dc2ff&danger=%23d81b60&warning=%23ff8f00&good=%236caf52&dark=%23000000&themename=cyanaudit

/* For use in src/lib/core/theming/_palette.scss */
$md-primary: (
    50 : #eae9f9,
    100 : #cac9ef,
    200 : #a6a5e5,
    300 : #8280da,
    400 : #6865d2,
    500 : #4d4aca,
    600 : #4643c5,
    700 : #3d3abd,
    800 : #3432b7,
    900 : #2522ab,
    A100 : #e7e6ff,
    A200 : #b4b3ff,
    A400 : #8280ff,
    A700 : #6967ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #ffffff,
    )
);

$md-accent: (
    50 : #e8f8ff,
    100 : #c5edff,
    200 : #9ee1ff,
    300 : #77d4ff,
    400 : #5acbff,
    500 : #3dc2ff,
    600 : #37bcff,
    700 : #2fb4ff,
    800 : #27acff,
    900 : #1a9fff,
    A100 : #ffffff,
    A200 : #feffff,
    A400 : #cbe8ff,
    A700 : #b2dcff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-danger: (
    50 : #fae4ec,
    100 : #f3bbcf,
    200 : #ec8db0,
    300 : #e45f90,
    400 : #de3d78,
    500 : #d81b60,
    600 : #d41858,
    700 : #ce144e,
    800 : #c81044,
    900 : #bf0833,
    A100 : #ffe9ed,
    A200 : #ffb6c4,
    A400 : #ff839a,
    A700 : #ff6986,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-warning: (
    50 : #fff2e0,
    100 : #ffddb3,
    200 : #ffc780,
    300 : #ffb14d,
    400 : #ffa026,
    500 : #ff8f00,
    600 : #ff8700,
    700 : #ff7c00,
    800 : #ff7200,
    900 : #ff6000,
    A100 : #ffffff,
    A200 : #fff6f2,
    A400 : #ffd3bf,
    A700 : #ffc2a6,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-good: (
    50 : #edf5ea,
    100 : #d3e7cb,
    200 : #b6d7a9,
    300 : #98c786,
    400 : #82bb6c,
    500 : #6caf52,
    600 : #64a84b,
    700 : #599f41,
    800 : #4f9638,
    900 : #3d8628,
    A100 : #d4ffc9,
    A200 : #abff96,
    A400 : #82ff63,
    A700 : #6eff49,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-dark: (
    50 : #e0e0e0,
    100 : #b3b3b3,
    200 : #808080,
    300 : #4d4d4d,
    400 : #262626,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #a6a6a6,
    A200 : #8c8c8c,
    A400 : #737373,
    A700 : #666666,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

