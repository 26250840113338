// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use 'sass:map';
@use '@angular/material' as mat;
@use "palette" as palette;
// Plus imports for other components in your app.
@use '@angular/material/theming';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

// Angular default
//$app-primary: mat.define-palette(mat.$indigo-palette);
//$app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
//$app-warn: mat.define-palette(mat.$red-palette);

$app-primary: mat.define-palette(palette.$md-primary);
$app-accent: mat.define-palette(palette.$md-accent);
$app-warning: mat.define-palette(palette.$md-warning);
$app-danger: mat.define-palette(palette.$md-danger);
$app-good: mat.define-palette(palette.$md-good);
$app-dark: mat.define-palette(palette.$md-dark);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$app-theme: mat.define-light-theme((
    color: (
        primary: $app-primary,
        accent: $app-accent,
        warn: $app-warning,
        danger: $app-danger,
        good: $app-good,
        dark: $app-dark
    )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($app-theme);

// Angular default button style extension
.mat-flat-button.mat-primary:hover {
    background-color: mat.get-color-from-palette($app-primary, 700);
}

.mat-flat-button.mat-accent:hover {
    background-color: mat.get-color-from-palette($app-accent, 700);
}

.mat-flat-button.mat-warn:hover {
    background-color: mat.get-color-from-palette($app-warning, 700);
}


// Angular custom color button styles
.mat-flat-button.mat-danger {
    background-color: mat.get-color-from-palette($app-danger);
    color: mat.get-color-from-palette($app-danger, default-contrast);
}

.mat-stroked-button.mat-danger {
    color: mat.get-color-from-palette($app-danger);
}

.mat-flat-button.mat-danger:hover {
    background-color: mat.get-color-from-palette($app-danger, 700);
}

.mat-flat-button.mat-good {
    background-color: mat.get-color-from-palette($app-good);
    color: mat.get-color-from-palette($app-good, default-contrast);
}

.mat-flat-button.mat-good:hover {
    background-color: mat.get-color-from-palette($app-good, 700);
}

.mat-flat-button.mat-dark {
    background-color: mat.get-color-from-palette($app-dark);
    color: mat.get-color-from-palette($app-dark, default-contrast);
}

.mat-flat-button.mat-dark:hover {
    background-color: mat.get-color-from-palette($app-dark, 700);
}


// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
    //noinspection CssUnknownProperty
    color-scheme: light dark;

    $color-config: mat.get-color-config($app-theme);
    $primary-palette: map.get($color-config, 'primary');
    $accent-palette: map.get($color-config, 'accent');
    $warning-palette: map.get($color-config, 'warning');
    $danger-palette: map.get($color-config, 'danger');
    --color-danger: mat.get-color-from-palette($danger-palette, 500);

    --ion-background-color: #eff1f8;

    /** primary **/
    --ion-color-primary: #3880ff;
    --ion-color-primary-rgb: 56, 128, 255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #3171e0;
    --ion-color-primary-tint: #4c8dff;

    /** secondary **/
    --ion-color-secondary: #3dc2ff;
    --ion-color-secondary-rgb: 61, 194, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #36abe0;
    --ion-color-secondary-tint: #50c8ff;

    /** tertiary **/
    --ion-color-tertiary: #7e7cee;
    --ion-color-tertiary-rgb: 82, 96, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #7270d9;
    --ion-color-tertiary-tint: #8684ff;

    /** success **/
    --ion-color-success: #6caf52;
    --ion-color-success-rgb: 45, 211, 111;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #5f9a49;
    --ion-color-success-tint: #7dcb5f;

    /** warning **/
    --ion-color-warning: #ff8f00;
    --ion-color-warning-rgb: 255, 143, 0;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e07e00;
    --ion-color-warning-tint: #ff9a1a;

    /** danger **/
    --ion-color-danger: #d81b60;
    --ion-color-danger-rgb: 235, 68, 90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #c51858;
    --ion-color-danger-tint: #ec1c68;

    /** dark **/
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    /** medium **/
    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;

    /** light **/
    --ion-color-light: #f5f7fb;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;

    /** fabButton **/
    --ion-color-fabButton: #f5f7fb;
    --ion-color-fabButton-rgb: 245, 247, 251;
    --ion-color-fabButton-contrast: #9290a7;
    --ion-color-fabButton-contrast-rgb: 0, 0, 0;
    --ion-color-fabButton-shade: #d8d9dd;
    --ion-color-fabButton-tint: #f6f8fb;

    /** Angular material **/
    --material-table-border-color: rgba(0, 0, 0, 0.12);

    /** custom colors **/
    --app-color-title-bar-background: #ffffff;

    --app-color-footer-background: #fdfdff;
    --app-color-footer-text: #4f4f73;

    --app-color-menu-background: #ffffff;
    --app-color-card-background: #f2f2f2;
    --app-color-menu-text: #4f4f73;
    --app-color-content-background: #ffffff;
    --app-color-content-header: #4f4f73;
    --app-color-hover-background: #fbfbff;
    --app-color-selected-item-background: #f8f8ff;
    --app-color-selected-item-background-border: #7e7cee;
    --app-color-loading-item-background-border: #9f9eff;
    --app-menu-shadow: 1px 1px 10px -1px rgba(0, 0, 0, 0.3);

    --app-menu-light-text: #9190a7;
    --app-menu-dark-text: #4f4f73;
    --app-menu-selected-text: #000000;
    --app-menu-selected-icon: #7e7cee;

    --app-input-opaque-background: #ffffff;
    --app-input-opaque-text: #000000;

    --app-color-modal-background: #ffffff;
    --app-color-modal-title: var(--ion-color-tertiary);

    --app-identifier-badge-background: #8bdaff;
    --app-identifier-badge-text: #12648a;

    /** document **/
    --app-color-document-title: #8989a3;
    --app-color-document-item-background: #ffffff;

    --app-color-repeatable-section-item-background: #f5f7fb;
    --app-color-repeatable-section-item-background-filled: #f0f7ed;
    --app-color-repeatable-section-item-background-hover: #d2d1ff;
    --app-color-repeatable-section-item-text: #000000;
    --app-color-repeatable-section-item-placeholder: #383a3e;

    /** images **/
    --app-color-image-background: var(--ion-color-dark);
    --app-color-image-text: var(--ion-color-dark-contrast);
}

.ion-color-fabButton {
    --ion-color-base: var(--ion-color-fabButton);
    --ion-color-base-rgb: var(--ion-color-fabButton-rgb);
    --ion-color-contrast: var(--ion-color-fabButton-contrast);
    --ion-color-contrast-rgb: var(--ion-color-fabButton-contrast-rgb);
    --ion-color-shade: var(--ion-color-fabButton-shade);
    --ion-color-tint: var(--ion-color-fabButton-tint);
}
